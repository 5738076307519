import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Maritime_Cover.jpg';
import metaImage from './assets/meta.jpg';

const MaritimePage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="industry-financial"
      helmet={{
        title: 'TileDB for Maritime',
        description: 'Use TileDB for vessel and ocean coverage insights and predictive analytics for all maritime data.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Maritime"
      title="Unparalleled vessel & ocean coverage, speed and predictive analytics for all maritime data"
      heroImage={BackgroundImage}
      icon={industries.maritime.icon}
      overview={
        <>
          <p>
            Climate change, the ever-increasing risk of geopolitical disruptions and environmental disasters call for the next level of
            vigilance and speed for maritime data providers.
          </p>
          <p>
            The TileDB Universal database is disrupting the distribution and analysis of maritime data at massive scale, allowing analysts
            and providers to gain competitive advantage and reduce risks.
          </p>
        </>
      }
      relatedItems={[dataTypes.ais, dataTypes.timeSeries, dataTypes.sar]}
    />
  );
};

export default MaritimePage;
